import { I18n } from '@lyfta/components-i18n'
import { filter, kebabCase, map, reverse } from 'lodash'
import { match } from 'path-to-regexp'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Icon } from '../../../Components/Icon'
import { MenuItem, MenuText } from '../styles'

export const Tabs = ({ route, userPermissions, ...props }) => {
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()

  const routesBaseOnPermissions = route.routes.filter(r => {
    const found = r.removeTabsBaseOnUserPermissions?.filter(item => {
      if (userPermissions[item] === false) return true

      return false
    })

    if (typeof found !== 'undefined' && Object.keys(found).length !== 0)
      return false

    return true
  })
  const routes = filter(reverse([...routesBaseOnPermissions]), r => !r.hide)

  let tabId = ''
  const tabIdMaker = tab => {
    if (tab.id) {
      tabId = `tab-${tab.id}`
    } else if (tab.breadcrumb) {
      tabId = `tab-${kebabCase(tab.breadcrumb.split('.').slice(-1)[0])}`
    } else if (tab.path && typeof tab.path === 'string') {
      tabId = `tab-${kebabCase(tab.path.split('/').slice(-1)[0])}`
    }
  }

  useEffect(() => {
    map(routes, (tab, index) => {
      const matcher = match(tab.path)
      if (matcher(location.pathname)) setActiveTab(index)
    })
  }, [location])

  return (
    <>
      {map(routes, (tab, index) => {
        const isSelected = index === activeTab

        tabIdMaker(tab)
        return (
          <MenuItem id={tabId} selected={isSelected} route={tab} {...props}>
            <Icon
              glyph={tab.glyph}
              width={20}
              height={20}
              fill={isSelected ? 'secondary_100' : 'black_600'}
              mr={4}
            />
            <MenuText selected={isSelected}>
              <I18n i18nKey={tab.label} mr={4} />
            </MenuText>
          </MenuItem>
        )
      })}
    </>
  )
}
Tabs.defaultProps = {
  userPermissions: {},
}

Tabs.propTypes = {
  route: PropTypes.object.isRequired,
  userPermissions: PropTypes.object,
}
