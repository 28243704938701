import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_FAVORITES = createAsyncAction('favorites/LOAD_FAVORITES')
export const ADD_TO_FAVORITES = createAsyncAction('favorites/ADD_TO_FAVORITES')
export const REMOVE_FROM_FAVORITES = createAsyncAction(
  'favorites/REMOVE_FROM_FAVORITES',
)
export const TRY_REMOVE_FROM_FAVORITES = 'favorites/TRY_REMOVE_FROM_FAVORITES'

export const loadFavorites = () =>
  apiCall({
    method: 'GET',
    endpoint: '/favorites',
    query: {
      include: 'content,content.favorites',
    },
    types: LOAD_FAVORITES,
  })

export const addToFavorites = (id, customMessage) =>
  apiCall({
    method: 'POST',
    customMessage: customMessage || {
      success: { message: 'toast.success.favoriteAdd' },
    },
    endpoint: `/favorites?include=content`,
    types: ADD_TO_FAVORITES,
    query: {
      data: {
        type: 'favorites',
        relationships: {
          content: {
            data: {
              type: 'contents',
              id,
            },
          },
        },
      },
    },
    updateRelationships: [
      {
        modelName: 'contents',
        id,
        relationship: 'favorites',
      },
    ],
    payload: {
      id,
    },
  })

export const removeFromFavorites = ({
  favoriteId,
  contentId,
  customMessage,
  removeData,
}) => {
  const requestObject = {
    method: 'DELETE',
    customMessage: customMessage || {
      success: { message: 'toast.success.favoriteRemove' },
    },
    endpoint: `/favorites/${favoriteId}?include=content`,
    types: REMOVE_FROM_FAVORITES,
    payload: {
      deletedId: favoriteId,
      contentId,
    },

    updateRelationships: [
      {
        modelName: 'contents',
        id: contentId,
        relationship: 'favorites',
      },
    ],
  }

  if (removeData)
    requestObject.removeData = {
      modelName: 'contents',
      id: contentId,
    }

  return apiCall(requestObject)
}

export const tryRemoveFromFavorites = (payload, resolve, customMessage) => ({
  type: TRY_REMOVE_FROM_FAVORITES,
  customMessage,
  payload,
  resolve,
})
