/* eslint-disable */
import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box, Flex, ParamLink } from '../../Components'

export const Container = styled(Flex).attrs(() => ({ m: 4 }))`
  min-height: 100%;
  overflow: hidden;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${themeGet('colors.neutral_400')};
  background: ${themeGet('colors.white')};
  &:hover {
    overflow: auto;
  }
`
export const LeftBlock = styled(Flex).attrs(() => ({ pl: 6, pt: 1 }))`
  width: 25%;
  max-width: '350px';
  flex-direction: column;
`

export const RightBlock = styled(Flex).attrs(() => ({ p: 5 }))`
  flex-direction: column;
  min-height: 100%;
  width: 75%;
  border-left: 1px solid ${themeGet('colors.neutral_400')};
`
export const Title = styled(Box).attrs(() => ({ py: 4 }))`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: ${themeGet('colors.teal_130')};
`
export const MenuItem = styled(Flex).attrs({
  // pl: 2,
  as: ParamLink,
})`
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 700;
  line-height: 18px;
  border-bottom: 1px solid ${themeGet('colors.neutral_400')};

  ${props =>
    !props.selected &&
    css`
      color: ${themeGet('colors.silver')};
    `}

  &:hover {
    cursor: pointer;
  }
`
export const MenuText = styled(Flex).attrs({ py: 4, pr: 4 })`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${p => (p.selected ? 700 : 500)};
  color: ${p =>
    p.selected
      ? themeGet('colors.secondary_100')
      : themeGet('colors.black_600')};
`
export const MenuTextBack = styled(Flex).attrs({ py: 4, pr: 4 })`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.black_600')};
`

export const CheckboxNew = styled(Flex).attrs(() => ({
  pr: 2,
  pt: 2,
  pb: 2,
}))`
  position: relative;
  align-items: center;
  font-weight: ${props => (props.active ? '700' : '400')};
  min-height: 20px;
  cursor: pointer;
  padding-left: 30px;

  :before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 13px;
    border: 2px solid ${themeGet('colors.gray')};
    border-radius: 3px;
  }
  ${props =>
    props.active &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 9px;
        height: 4px;
        left: 3.5px;
        top: 18px;
        border-style: solid;
        border-width: 2px;
        border-bottom-color: ${themeGet('colors.black_600')};
        border-left-color: ${themeGet('colors.black_600')};
        border-top-color: ${themeGet('colors.clear')};
        border-right-color: ${themeGet('colors.clear')};
        transform: rotate(-55deg);
      }
    `}
`
