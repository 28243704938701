import { isTeacherApp } from '@lyfta/components-config'
import {
  TYPE_COLLECTION,
  TYPE_LESSON_PLAN_TEMPLATE,
  TYPE_SERIES,
  TYPE_STORY_WORLD,
} from '@lyfta/components-content/src/Constants/contents'
import { addContentToCollection } from '@lyfta/components-content/src/Store/Actions/collections'
import { getCollections } from '@lyfta/components-content/src/Store/Selectors/collections'
import { getIcon } from '@lyfta/components-content/src/Utils'
import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getAuthorId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import {
  I18n,
  TranslatedField,
  formatDate,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import {
  AnimatedInputNew,
  Bin,
  ButtonNew,
  ContentWarning,
  Flex,
  Graph,
  Icon,
  Location as Loc,
  Lock,
  Modal,
  Plus,
  ReactSelect,
  Series,
  Storyworld,
  capitalizeFirstLetter,
  convertArrayToString,
  createAgeRangeLabel,
  reactEvent,
} from '@lyfta/components-ui'
import { take } from 'lodash'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Player from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { requestCreateCollectionWithContent } from '../../Store/Actions/collections'
import Favorite from '../Favorite'
import {
  AuthorContainer,
  BodyContainer,
  ButtonOnCard,
  Container,
  ContentInfo,
  Controls,
  DescriptionContainer,
  DurationContainer,
  FooterContainer,
  HeaderContainer,
  KeywordContainer,
  LocationContainer,
  PublishedContainer,
  StyledButton,
  ThumbHolder,
  Title,
  TitleContainer,
  TypeLabel,
  TypeLabelContainer,
  TypeRow,
  ViewContainer,
} from './styles'

const Card = ({
  item,
  onSelect,
  onView,
  selectable,
  height,
  width,
  showDeleteFavouriteConfirmation,
  isLoading,
  showTrailer,
  favoriteProperty,
  section,
  lyftaContent,
  isCollectionEditor,
  deleteIconAction,
  addToCollection,
  paths,
  isSelected,
  removeData,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [createCollectionForm, setCreateCollectionForm] = useState(false)
  const [newCollectionName, setNewCollectionName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()
  const handlePreview = () => {
    reactEvent({
      action: 'clicked_content_card',
      params: {
        content_type: get(item, 'type') || false,
        content_id: get(item, 'id'),
        content_name: getTranslatedField(item, 'name'),
        content_section: section,
      },
    })

    onView(item)
  }

  const handleSelect = () => {
    onSelect(item)
  }

  const locale = useSelector(getLocale)
  const viewerAuthorId = useSelector(getAuthorId)

  const localeCapitalized = capitalizeFirstLetter(locale)
  const contentId = get(item, 'id')
  const isLicensed = get(item, 'isLicensed')
  const type = get(item, 'type') || false
  const parentContent = get(item, 'parent') || false
  const parentName = get(item, 'parentName') || false
  const createdByLyfta = get(item, 'createdByLyfta') || false
  const trailerUrl = get(item, 'trailerUrl') || false
  const posterUrl = get(item, 'posterUrl') || false
  const authorName = get(item, 'authorName') || ''
  const authorId = get(item, 'author.id') || get(item, 'authorId') || false
  const ageRangeLabel = createAgeRangeLabel(get(item, 'ageRanges'))

  const collectionList = useSelector(getCollections)

  const getCollectionList = () => {
    const collections = collectionList.map(collection => {
      return { value: collection?.id, label: collection?.nameEn }
    })

    return collections
  }
  const keywords = convertArrayToString({
    arr: get(item, `keywords${localeCapitalized}`),
    conjunction: false,
    maxItems: 3,
  })

  let keywordsString = getTranslation('collections.keywords')

  if (keywords) {
    keywordsString = `${keywordsString} ${keywords}`
  }

  const favorite = get(item, 'favorites') || favoriteProperty || false
  const contentWarningDescription = get(
    item,
    'contentWarningDescription',
    false,
  )

  const bbfcRatingIcon = get(item, 'bbfcRatingIconUrl', false)
  const bbfcRatingDescription = get(
    item,
    `bbfcRatingDescription${localeCapitalized}`,
    false,
  )
  const bbfcRatingName = get(item, 'bbfcRatingName', false)
  const bbfcRatingShortDescription =
    bbfcRatingDescription && (bbfcRatingName === 'U' || bbfcRatingName === 'PG')
      ? `${bbfcRatingName} - ${bbfcRatingDescription.split('-')[0]}`
      : bbfcRatingName

  const viewedCount = get(item, 'viewedCount') || false
  const filmDuration = get(item, 'filmDuration') || false
  const filmDurationRemainingSeconds = `${
    filmDuration % 60 > 9 ? filmDuration % 60 : `0${filmDuration % 60}`
  }`
  const filmDurationMinutesSeconds = `${Math.floor(
    filmDuration / 60,
  )}:${filmDurationRemainingSeconds}`
  const bbfcNoContentWarning =
    !!bbfcRatingDescription && !contentWarningDescription
  const publishedTime = get(item, 'createdAt') || ''
  const itemId = get(item, 'id') || ''
  const collectionId = get(item, 'collection.id') || ''

  const isOwner = String(viewerAuthorId) === String(authorId)

  const authorNameLabel = isOwner ? (
    <>
      {' '}
      <I18n i18nKey="collections.createdByYou" />
    </>
  ) : (
    <>
      {' '}
      <I18n
        i18nKey="collections.createdByTeacher"
        options={{
          authorName,
        }}
      />
    </>
  )

  if (isLoading)
    return (
      <Container width={width} height={height}>
        <Skeleton
          width={300}
          height={167}
          style={{ lineHeight: 24, borderRadiusTop: '8px' }}
        />
        <Skeleton
          width={150}
          height={30}
          style={{ marginLeft: '16px', borderRadiusTop: '16px' }}
        />
        <Skeleton
          width={250}
          height={22}
          marginTop={10}
          style={{ marginLeft: '16px', marginTop: '10px' }}
        />
        <Skeleton
          width={250}
          height={22}
          style={{ marginLeft: '16px', marginTop: '10px' }}
        />
        <Flex flexDirection="row">
          <Skeleton
            width={24}
            height={22}
            style={{
              marginLeft: '16px',
              marginTop: '10px',
              borderRadius: '50%',
            }}
          />

          <Skeleton
            width={150}
            height={22}
            style={{ marginLeft: '16px', marginTop: '10px' }}
          />
        </Flex>
      </Container>
    )
  const handleCreateCollectionWithContent = () => {
    setIsOpen(false)
    new Promise(resolve =>
      dispatch(
        requestCreateCollectionWithContent(
          { id: itemId, type: item.type, name: newCollectionName },
          resolve,
        ),
      ),
    ).then(value => {
      history.push(paths.collection.edit(value))
    })
  }

  const handleContentToCollection = () => {
    setIsOpen(false)

    const collectionContent = [
      {
        id: itemId,
        type: item.type,
      },
    ]

    dispatch(
      addContentToCollection({
        itemsSelected: collectionContent,
        id: selectedOption,
        customMessage: { success: { message: 'toast.success.contentAdded' } },
      }),
    )
    history.push(paths.collection.edit(selectedOption))
  }

  const handleCollectionName = event => {
    setNewCollectionName(event.target.value)
  }

  const onChangeOrSubmit = e => {
    setSelectedOption(e.value)
  }

  const collectionDropdown = () => {
    if (createCollectionForm) {
      return (
        <Flex mt={2}>
          <AnimatedInputNew
            // description={getTranslation('collections.collectionName')}
            rows={3}
            label={<I18n i18nKey="fields.collectionName" />}
            mb={3}
            mt={3}
            width="100%"
            onChange={handleCollectionName}
            name="collectionName"
            // viewOnly={viewOnly}
          />
        </Flex>
      )
    }

    return (
      <>
        <ReactSelect
          data-intercom-target="add-content-to-collection"
          description=""
          mt={4}
          id="dropdown-choose-collection"
          label={<I18n text="fields.addToCollections" sentenceCase />}
          name="collection"
          options={getCollectionList()}
          // value={getClassList().filter(option => option.value === selectedOption)}
          onChange={onChangeOrSubmit}
        />
        <ButtonNew
          leadingIcon={
            <Icon glyph={Plus} fill="blue_100" mr={2} height={18} width={18} />
          }
          default={{
            padding: '10px 0',
          }}
          buttonDiv
          type="link"
          mt={3}
          width="fit-content"
          onClick={() => setCreateCollectionForm(true)}
          // onClick={() => handleCreateCollectionWithContent()}
        >
          {getTranslation('actions.createNewCollection')}
        </ButtonNew>
      </>
    )
  }

  return (
    <>
      <Container
        data-intercom-target={contentId}
        height={height}
        width={width}
        id={`card-${contentId}`}
        onClick={selectable ? handleSelect : handlePreview}
        isSelected={isSelected}
      >
        <HeaderContainer position="relative">
          {/* DELETE */}
          {deleteIconAction && (
            <ButtonOnCard>
              <ButtonNew
                leadingIcon={<Icon glyph={Bin} height={18} width={18} />}
                type="icon"
                style={{
                  maxHeight: '30px',
                  maxWidth: '30px',
                  minHeight: '30px',
                  minWidth: '30px',
                  borderWidth: 0,
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
                onClick={event => {
                  event.stopPropagation()
                  deleteIconAction(item)
                }}
                delete
                secondary
              />
            </ButtonOnCard>
          )}

          {/* ADD TO COLLECTION */}
          {addToCollection && !lyftaContent && !isCollectionEditor && (
            <ButtonOnCard title="add to collection">
              <ButtonNew
                leadingIcon={
                  <Icon glyph={Plus} fill="blue_100" height={18} width={18} />
                }
                type="icon"
                title={getTranslation('actions.addToCollection')}
                style={{
                  maxHeight: '30px',
                  maxWidth: '30px',
                  minHeight: '30px',
                  minWidth: '30px',
                  borderWidth: 0,
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  boxShadow: shadows.lifted,
                }}
                onClick={event => {
                  event.stopPropagation()
                  // deleteIconAction(item)
                  setIsOpen(true)
                }}
                secondary
              />
            </ButtonOnCard>
          )}
          {trailerUrl && showTrailer ? (
            <Player
              controls
              width="100%"
              height="100%"
              playing={false}
              url={trailerUrl}
            />
          ) : (
            <ThumbHolder type={type} image={posterUrl}>
              {filmDuration && (
                <DurationContainer>
                  {filmDurationMinutesSeconds}
                </DurationContainer>
              )}
              {viewedCount && (
                <ViewContainer>
                  <Icon
                    glyph={Graph}
                    fill={themeGet('colors.black_300')}
                    height={12}
                    mr={2}
                    width={12}
                  />{' '}
                  {viewedCount}
                  <Flex ml={1}>
                    {getTranslation(`actions.views`).toLocaleLowerCase()}
                  </Flex>
                </ViewContainer>
              )}
            </ThumbHolder>
          )}
        </HeaderContainer>

        <BodyContainer>
          <TypeRow>
            <TypeLabelContainer bbfcNoContentWarning={bbfcNoContentWarning}>
              <Icon
                glyph={getIcon(type)}
                width={16}
                height={16}
                fill={themeGet('colors.black_600')}
              />

              <TypeLabel>
                {' '}
                {getTranslation(`core.contentTypes.${type}`)}
              </TypeLabel>
            </TypeLabelContainer>
            {contentWarningDescription && (
              <Icon
                title={contentWarningDescription}
                glyph={ContentWarning}
                height="24px"
                width="24px"
                ml={1}
              />
            )}
            {bbfcRatingIcon && (
              <Icon
                title={bbfcRatingShortDescription}
                glyph={
                  <img
                    alt={bbfcRatingShortDescription}
                    src={bbfcRatingIcon}
                    height="24px"
                    width="26px"
                  />
                }
                ml={1}
              />
            )}
          </TypeRow>
          <TitleContainer>
            <Title>
              {type === TYPE_COLLECTION && !createdByLyfta ? (
                <>
                  {ageRangeLabel}
                  <TranslatedField
                    title={getTranslatedField(item, 'name')}
                    object={item}
                    field="name"
                    id={`card-title-${contentId}`}
                  />{' '}
                  {collectionId
                    ? `(ID: ${take(String(collectionId), 4).join('')})`
                    : ''}
                </>
              ) : (
                <TranslatedField
                  object={item}
                  field="name"
                  id={`card-title-${contentId}`}
                  title={getTranslatedField(item, 'name')}
                />
              )}
            </Title>
          </TitleContainer>

          <Flex flexDirection="column" width="100%">
            <TranslatedField
              tag={DescriptionContainer}
              title={
                type === TYPE_COLLECTION
                  ? getTranslatedField(item, 'longDescription')
                  : getTranslatedField(item, 'shortDescription')
              }
              object={item}
              field={
                type === TYPE_COLLECTION
                  ? 'longDescription'
                  : 'shortDescription'
              }
              ellipsis
            />
            {type === TYPE_STORY_WORLD ||
              (type === TYPE_SERIES && (
                <LocationContainer>
                  <Icon glyph={Loc} width={16} height={16} fill="gray" />
                  <Flex ml={2}>
                    <TranslatedField object={item} field="country" />
                  </Flex>
                </LocationContainer>
              ))}

            {type === TYPE_STORY_WORLD &&
              (parentContent.seriesName || parentName) && (
                <LocationContainer>
                  <Icon
                    title={getTranslation('core.contentTypes.series')}
                    glyph={Series}
                    width={16}
                    height={16}
                    fill="gray"
                  />
                  <Flex ml={2}>{parentContent.seriesName || parentName}</Flex>
                </LocationContainer>
              )}
            {type !== TYPE_STORY_WORLD &&
              type !== TYPE_LESSON_PLAN_TEMPLATE &&
              type !== TYPE_SERIES &&
              (parentContent || parentName) && (
                <LocationContainer>
                  <Icon
                    title={getTranslation('core.contentTypes.story_world')}
                    glyph={Storyworld}
                    width={16}
                    height={16}
                    fill="gray"
                  />
                  <Flex ml={2}>
                    {parentContent.storyWorldName || parentName}
                  </Flex>
                </LocationContainer>
              )}
            {type === TYPE_COLLECTION && keywords && (
              <KeywordContainer>{keywordsString}</KeywordContainer>
            )}
          </Flex>
          <ContentInfo>
            {type === TYPE_COLLECTION && createdByLyfta && (
              <I18n
                tag={AuthorContainer}
                i18nKey="collections.createdByLyfta"
              />
            )}
            {type === TYPE_COLLECTION && !createdByLyfta && (
              <AuthorContainer>{authorNameLabel}</AuthorContainer>
            )}
            {publishedTime && (
              <PublishedContainer>
                {getTranslation('content.labels.published')}{' '}
                {formatDate(publishedTime, { year: 'numeric' })}
              </PublishedContainer>
            )}
          </ContentInfo>
        </BodyContainer>

        <FooterContainer>
          {isLicensed && (
            <Controls>
              {isTeacherApp && (
                <Flex
                  width="50%"
                  id={`div-add-remove-favorite-content-id-${contentId}`}
                  justifyContent="center"
                >
                  <Favorite
                    disabled={isOwner && type === TYPE_COLLECTION}
                    heartOutline
                    favoriteProperty={favorite}
                    contentId={contentId}
                    showDeleteConfirmation={showDeleteFavouriteConfirmation}
                    isCollection={type === TYPE_COLLECTION}
                    removeData={removeData}
                  />
                </Flex>
              )}
              <StyledButton
                id={`btn-view-content-id-${contentId}`}
                inverse
                noborder
                type="link"
                isTeacherApp={isTeacherApp}
              >
                <I18n i18nKey={lyftaContent ? 'actions.add' : 'actions.view'} />
              </StyledButton>
            </Controls>
          )}

          {!isLicensed && (
            <Controls>
              <Flex width="100%" justifyContent="center">
                <Icon
                  fill={themeGet('colors.black_600')}
                  glyph={Lock}
                  width={18}
                  height={20}
                />
              </Flex>
            </Controls>
          )}
        </FooterContainer>
      </Container>
      {isOpen && (
        <Modal
          columnDisplay
          hideClose
          isOpen={isOpen}
          maxWidth="500px"
          form={{
            fields: collectionDropdown,
          }}
          modalStyle={{
            minHeight: '40%',
          }}
          buttons={{
            okText: createCollectionForm ? (
              <I18n text="actions.createCollection" />
            ) : (
              <I18n text="actions.add" />
            ),
            cancellText: createCollectionForm ? (
              ''
            ) : (
              <I18n text="actions.cancel" />
            ),
          }}
          content={{
            title: createCollectionForm
              ? 'core.modals.nameCollection'
              : 'core.modals.addToCollection',
            description: createCollectionForm
              ? 'core.modals.nameCollectionDescription'
              : 'core.modals.addToCollectionDescription',
          }}
          // text={getTranslation('actions.selectClassToTeach')}

          onCallback={() =>
            createCollectionForm
              ? handleCreateCollectionWithContent()
              : handleContentToCollection()
          }
          onDismissModal={() =>
            createCollectionForm
              ? setCreateCollectionForm(false)
              : setIsOpen(false)
          }
        />
      )}
    </>
  )
}

Card.defaultProps = {
  item: null,
  favoriteProperty: null,
  selectable: false,
  onSelect: noop,
  onView: noop,
  height: '410px',
  width: '300px',
  section: '',
  showDeleteFavouriteConfirmation: false,
  isLoading: false,
  showTrailer: false,
  lyftaContent: false,
  isCollectionEditor: false,
  deleteIconAction: false,
  addToCollection: false,
  paths: {},
  isSelected: false,
  removeData: false,
}

Card.propTypes = {
  favoriteProperty: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  item: PropTypes.shape({
    type: PropTypes.string,
    posterUrlSmall: PropTypes.string,
  }),
  isCollectionEditor: PropTypes.bool,
  lyftaContent: PropTypes.bool,
  section: PropTypes.string,
  selectable: PropTypes.bool,
  deleteIconAction: PropTypes.bool,
  addToCollection: PropTypes.bool,
  showDeleteFavouriteConfirmation: PropTypes.bool,
  showTrailer: PropTypes.bool,
  isSelected: PropTypes.bool,
  removeData: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onView: PropTypes.func,
  paths: PropTypes.object,
}

export default Card
