import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

import { Box } from '../../Box'
import { Label, StyledRadioIcon, StyledRadioInput } from './styles'

const renderField = ({ handleBlur, input, meta, ...rest }) => {
  return (
    <>
      <StyledRadioIcon checked={input.checked} {...rest} />
      <StyledRadioInput
        {...input}
        {...rest}
        onBlur={() => {
          handleBlur()
          // input.onBlur()
        }}
      />
    </>
  )
}

renderField.defaultProps = {
  handleBlur: noop,
}

renderField.propTypes = {
  handleBlur: PropTypes.func,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

const Radio = ({ label, name, noForm, ...rest }) => {
  if (noForm)
    return (
      <Label>
        <StyledRadioIcon checked={rest.checked} {...rest} />
        <Box width={1}>{label}</Box>
      </Label>
    )
  return (
    <Label>
      <Field name={name} render={renderField} type="radio" {...rest} />
      <Box width={1}>{label}</Box>
    </Label>
  )
}

Radio.displayName = 'Radio'

Radio.defaultProps = {
  noForm: false,
}

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  noForm: PropTypes.bool,
}

export default Radio
