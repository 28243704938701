/* eslint-disable */

import { css, styled, themeGet } from '@lyfta/components-theme'
import { Arrow, Box, Flex } from '@lyfta/components-ui'

export const FilterItem = styled(Flex).attrs(() => ({
  pr: 2,
  pt: 2,
  pb: 2,
}))`
  position: relative;
  align-items: center;
  font-weight: ${props => (props.active ? '700' : '400')};
  min-height: 20px;
  cursor: pointer;
  padding-left: 30px;

  :before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 13px;
    border: 2px solid ${themeGet('colors.gray')};
    border-radius: 3px;
  }
  ${props =>
    props.active &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 9px;
        height: 4px;
        left: 3.5px;
        top: 18px;
        border-style: solid;
        border-width: 2px;
        border-bottom-color: ${themeGet('colors.black_600')};
        border-left-color: ${themeGet('colors.black_600')};
        border-top-color: ${themeGet('colors.clear')};
        border-right-color: ${themeGet('colors.clear')};
        transform: rotate(-55deg);
      }
    `}
`

export const SectionContent = styled(Flex).attrs(() => ({
  pt: 3,
}))`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`

export const CheckboxContainerGrid = styled(Box)`
  column-count: 1;
  width: 100%;
  column-gap: 5px;
`

export const Container = styled(Flex).attrs(() => ({}))`
  min-height: 100%;
  overflow: auto;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: ${themeGet('colors.neutral_100')};
`
export const LeftBlock = styled(Flex).attrs(() => ({ pl: 4, pb: 6 }))`
  width: 40%;
  height: 100%;
  max-width: 500px;
  min-width: 300px;
  flex-direction: column;
  background-color: ${themeGet('colors.neutral_100')};
`

export const RightBlock = styled(Flex).attrs(() => ({ px: 3, pb: 2 }))`
  background-color: ${themeGet('colors.neutral_200')};

  flex-direction: row;
  width: 75%;
  height: 100%;
  flex-wrap: wrap;
  overflow: auto;
  border-left: 1px solid ${themeGet('colors.neutral_400')};
`
export const CardsContainer = styled(Flex).attrs(() => ({ p: 1 }))`
  flex-direction: row;
  min-height: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 35px;
`
export const Title = styled(Box).attrs(() => ({}))`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
  color: ${themeGet('colors.black_800')};
`
export const TitleModal = styled(Box).attrs(() => ({}))`
  width: 100%;
  letter-spacing: 0.5px;
  width: 100%;
  font-weight: 700;
  font-size: 23px;
  line-height: 32px;
  color: ${props =>
    props.color ? themeGet(props.color) : themeGet('colors.black_800')};
`

export const LanguageContainer = styled(Flex)`
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.doveGray')};
  margin-bottom: 20px;
`
export const ArrowWithMargin = styled(Arrow)`
  margin-left: 5px;
`
export const SubscribeContainer = styled(Flex)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${p =>
    p.color
      ? themeGet(`colors.${p.color}`)(p)
      : themeGet('colors.secondary_130')};
`
export const AuthorContainer = styled(Flex)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_600')};
  align-items: center;
`
export const FileContainer = styled(Flex)`
  width: 100%;
  max-width: 300px;
  flex-direction: column;
`
export const ImageWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin: 0 16px;
  border-radius: 10px;
`
export const MenuItem = styled(Flex).attrs({})`
  justify-content: left;
  align-items: center;
  width: 100%;
  font-weight: 700;
  line-height: 18px;

  ${props =>
    !props.selected &&
    css`
      color: ${themeGet('colors.silver')};
    `}

  &:hover {
    cursor: pointer;
  }
`

export const ThumbImage = styled('img')`
  max-height: 196px;
  max-width: 294px;
  border-radius: 10px;
`

export const MenuText = styled(Flex).attrs({ py: 4, pr: 4 })`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.secondary_100')};
`
export const MenuTextBack = styled(Flex).attrs({})`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.black_900')};
`
export const TitleViewOnly = styled(Flex).attrs({ mb: 1 })`
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_800')};
`
export const CardAuthorIcon = styled(Flex)`
  width: 30px;
  height: 30px;
  ${p => p.profileImageUrl && `background-image: url(${p.profileImageUrl});`}

  background-color: ${p =>
    p.lyfta ? themeGet('colors.primary_100') : 'white'};
  border: ${p =>
    !p.lyfta
      ? p.preferredColour
        ? `2px solid ${themeGet(`colors.${p.preferredColour}`)(p)}`
        : `2px solid ${themeGet('colors.primary_100')(p)}`
      : `none`};

  color: ${p =>
    p.preferredColour
      ? themeGet(`colors.${p.preferredColour}`)
      : themeGet('colors.primary_100')};

  font-weight: 600;
  font-size: 12px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  letter-spacing: 1.5px;
margin-bottom: 5px;
  ${p => !p.lyfta && `background-size: cover; background-position: center;`}

`

export const TextColor = styled(Flex).attrs({})`
  color: ${themeGet('colors.black_700')};
`

export const TitleGuideLine = styled(Flex).attrs({})`
  font-family: 'Walsheim';
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.5px;
`

export const SubtitleGuideLine = styled(Flex).attrs({})`
  font-family: 'Walsheim';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 20px;
`
export const Subtitle2GuideLine = styled(Flex).attrs({})`
  font-family: 'Walsheim';
  font-size: 16px;
  font-style: Oblique;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 14px;
`

export const BodyGuideLine = styled.p`
  font-family: 'Walsheim';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  margin-top: 10px;
  flex-direction: column;
`
export const InlineLink = styled.a`
  color: blue; /* O el color que prefieras */
  text-decoration: underline;
  display: inline; /* Asegura que el enlace sea en línea */
`
export const AddToCollectionButton = styled(Box).attrs(() => ({}))`
  width: 100%;
  position: absolute;
  width: 16px;
  height: 16px;
  right: 170px;
  bottom: 13px;
  z-index: 999;
`
