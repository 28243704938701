import get from 'lodash/get'

import { HS_JOB_ROLES } from '../../constants'
import { createReducer } from '../../helpers'
import {
  CLEAR_ERRORS,
  LOAD_VIEWER,
  LOG_OUT,
  SET_REDIRECT_FINAL_STEP,
} from '../Actions/viewer'

const permissionsDefault = {
  allowQuestTracker: false,
  allowCustomLessonPlans: null,
  restrictContentToLesson: false,
  isTrialing: false,
}

const emailNotificationPreferencesDefault = {
  newsAndAnnouncements: false,
  subscribedCollectionsUpdates: false,
  collectionSubscribers: false,
  collectionsImpact: false,
}

const initialState = {
  id: null,
  error: null,
  isLoading: false,
  isLoaded: false,
  fromSocial: false,
  type: null,
  permissions: permissionsDefault,
  emailNotificationPreferences: emailNotificationPreferencesDefault,
  resourceId: null,
  lessonPlanId: null,
  path: null,
  jobRoleHS: null,
  licenseType: null,
  usageTotals: null,
}

const loadViewer = (state, { payload }) => {
  const teachers = get(payload, 'data.teachers')
  const viewer = teachers ? Object.values(teachers)[0] : ''
  const jobRole = get(payload, `data.teachers[0].attributes.jobRole`, null)

  return state?.merge({
    id: get(payload, 'data.meta[/user].data[0].id', null),
    authorId: get(viewer, `attributes.authorId`, null),
    jobRoleHS: get(HS_JOB_ROLES, jobRole, null),
    licenseType: get(viewer, `attributes.licenseType`, null),
    organization: get(payload, 'data.organizations', null),
    type: get(payload, 'data.meta[/user].data[0].type', null),
    permissions: get(payload, 'data.meta[/user].meta.permissions', null),
    emailNotificationPreferences: get(
      viewer,
      `attributes.emailNotificationPreferences`,
      null,
    ),
    usageTotals: get(payload, 'data.meta[/user].meta.usageTotals', null),
    isLoaded: true,
  })
}
const redirectFinalStepHandler = (state, { redirectFinalStep }) => {
  return state?.merge({ redirectFinalStep })
}

const handlers = {
  [CLEAR_ERRORS]: state => state?.merge({ error: null }),

  [LOAD_VIEWER.SUCCESS]: loadViewer,
  [SET_REDIRECT_FINAL_STEP]: redirectFinalStepHandler,
  [LOG_OUT]: state => state?.merge(initialState),
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
