import { appIdentifier } from '@lyfta/components-config'
import { setEmail } from '@lyfta/components-data/src/Store/Actions/auth'
import { getError } from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validate from 'validate.js'

import { AnimatedInput, ButtonNew } from '../../../../Components'
import { emailConstraint } from '../../../../Constants/constraints'
import { ErrorBlock, LinkMailto } from '../../styles'

const SignInFormFieldset = ({
  handleSubmit,
  // pristine,
  // invalid,
  customButton,
  values,
}) => {
  const error = useSelector(getError)
  const dispatch = useDispatch()

  const onBlur = () => {
    let emailAllowed = false
    const email = get(values, 'email')
    if (email) emailAllowed = validate({ email }, { ...emailConstraint })
    if (emailAllowed === undefined) dispatch(setEmail(email))
  }

  return (
    <>
      <AnimatedInput
        autoComplete="off"
        id="input-email"
        label={<I18n text="auth.email" />}
        mb={3}
        name="email"
        type="email"
        onBlur={onBlur}
      />

      <AnimatedInput
        autoComplete="off"
        id="input-password"
        label={<I18n text="auth.password" />}
        mb={2}
        name="password"
        type="password"
      />
      {customButton ? (
        customButton({ handleSubmit })
      ) : (
        <ButtonNew
          // disabled={pristine || invalid}
          id="btn-login"
          mt={2}
          secondary={appIdentifier !== 'teacher'}
          type="task"
          width={1}
          onClick={handleSubmit}
        >
          <I18n text="auth.login" />
        </ButtonNew>
      )}

      <ErrorBlock>
        {error && (
          <TransLink
            catchString={getTranslation('auth.contactUs')}
            components={{
              Link: (
                <LinkMailto href={getTranslation('auth.lyftaContactUsLink')} />
              ),
            }}
            wrapper={`<Link>${getTranslation('auth.contactUs')}</Link>`}
          >
            {error}
          </TransLink>
        )}
      </ErrorBlock>
    </>
  )
}

SignInFormFieldset.defaultProps = {
  customButton: noop,
  values: {},
}

SignInFormFieldset.propTypes = {
  customButton: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
}

export default SignInFormFieldset
